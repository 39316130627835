import React, { useContext } from "react"

import { Section, Container } from "components/anti/grid/grid"
import { InputTextV2 } from "components/anti/form/form"
import { LangContext } from "../../../context/lang-context"

export const SearchHeader = ({ callback, query, length }) => {
  const { lang } = useContext(LangContext)

  return (
    <Section className="search-header pb-main-sm">
      <Container className="mw-xl">
        <div className="row">
          <div className="col-lg-8">
            <h1>{lang === "EN" ? "Search Result" : "Hasil Pencarian"}</h1>
            <h6>
              {length} {lang === "EN" ? "items found for" : "penemuan untuk"} "
              {query}"
            </h6>
          </div>
          <div className="col-lg-4 col-right">
            <InputTextV2
              onKeyPress={e => callback(e)}
              placeholder={
                lang === "EN" ? "Enter keyword(s)" : "Masukkan kata kunci"
              }
            >
              <div className="form-search-icon">
                <i className="air ai-search" />
              </div>
            </InputTextV2>
          </div>
        </div>
      </Container>
    </Section>
  )
}
