import React, { useEffect, useContext } from "react"
import { gql, useLazyQuery } from "@apollo/client"

import Layout from "components/layout"
import Seo from "components/seo"
import { LangContext } from "../context/lang-context"

import { SearchHeader } from "components/shift/search/header.js"
import { SearchResult } from "components/shift/search/result.js"
import { WithLocation } from "components/anti/utils/utils"
import { navigate } from "gatsby-link"

const SearchPage = ({ search }) => {
  const [getContent, { data, loading }] = useLazyQuery(SEARCH_QUERY)
  const { lang } = useContext(LangContext)

  useEffect(() => {
    if (!data && search.q) {
      getContent({ variables: { search: search.q } })
    }
  }, [])

  const searchCb = e => {
    navigate(`/search?q=${e}`)
    getContent({ variables: { search: e } })
  }

  const resultList =
    lang === "EN"
      ? data?.ENSearch?.edges
      : data?.IDSearch?.edges || data?.ENSearch?.edges

  return (
    <Layout>
      <Seo title="Search" />
      <SearchHeader
        callback={searchCb}
        query={search.q}
        length={resultList?.length}
      />
      <SearchResult data={resultList} loading={loading} />
    </Layout>
  )
}

const SEARCH_QUERY = gql`
  query search($search: String) {
    ENSearch: contentNodes(
      where: {
        search: $search
        contentTypes: [PAGE, POST, TEAMS, JOBS, PRACTICES]
        language: EN
      }
      first: 100
    ) {
      edges {
        cursor
        node {
          slug
          ... on Page {
            id
            title
            slug
            excerpt
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Post {
            id
            title
            slug
            excerpt
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Job {
            id
            title
            slug
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Team {
            id
            title
            slug
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Practice {
            id
            contentType {
              node {
                name
              }
            }
            slug
            title
            translation(language: EN) {
              slug
            }
            practice {
              status {
                taxonomy {
                  slug
                }
              }
            }
          }
        }
      }
    }
    IDSearch: contentNodes(
      where: {
        search: $search
        contentTypes: [PAGE, POST, TEAMS, JOBS, PRACTICES]
        language: ID
      }
      first: 100
    ) {
      edges {
        cursor
        node {
          slug
          ... on Page {
            id
            title
            slug
            excerpt
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Post {
            id
            title
            slug
            excerpt
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Job {
            id
            title
            slug
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Team {
            id
            title
            slug
            contentType {
              node {
                name
              }
            }
            translation(language: EN) {
              slug
            }
          }
          ... on Practice {
            id
            contentType {
              node {
                name
              }
            }
            slug
            title
            translation(language: EN) {
              slug
            }
            practice {
              status {
                taxonomy {
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`

export default WithLocation(SearchPage)
