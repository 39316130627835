import React, { useContext } from "react"

import { Section, Container } from "components/anti/grid/grid"
import { Link } from "components/anti/link/link"

import { LangContext } from "../../../context/lang-context"

export const SearchResult = ({ data, loading }) => {
  const { lang } = useContext(LangContext)

  return (
    <Section className="search-result pt-0">
      <Container className="mw-xl">
        {/* Search result found */}
        <div className="result-list">
          {loading && (
            <div className="loading-page">
              <div className="loading-wrapper">
                <i class="fas fa-spinner fa-pulse"></i>
              </div>
            </div>
          )}
          {!loading &&
            data &&
            data.map(item => {
              const type = item.node.contentType.node.name
              const content = item.node
              const slug = content?.translation?.slug
              let link = ""
              if (type === "post") {
                link = "/resources/details"
              } else if (type === "teams") {
                link = "/the-team"
              } else if (type === "jobs") {
                link = "/work-with-us"
              } else if (type === "practices") {
                link = "/practice-areas"
              } else {
                link = ""
              }

              let realLink = ""
              if (type === "practices") {
                const practiceStatus =
                  item?.node?.practice?.status?.taxonomy?.slug
                if (practiceStatus === "active") {
                  realLink = `${link}/${slug}`
                } else {
                  realLink = `${link}`
                }
              } else {
                realLink = `${link}/${slug}`
              }

              return (
                <Link to={realLink} className="result-item" key={content.id}>
                  <h3>{content.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: content.excerpt }} />
                  <small>
                    http://shiftlegal.id
                    {link}/{slug}
                  </small>
                </Link>
              )
            })}
        </div>

        {/* No search result */}
        {data?.length === 0 && (
          <div className="result-none">
            <p className="mb-0">
              {lang === "EN"
                ? "Please try different keyword(s)"
                : "Masukkan kata kunci yang lain"}
            </p>
          </div>
        )}
      </Container>
    </Section>
  )
}
